export const testimonal = [
    {
      id: 1,
      name: "LOREM IPSUM",
      post: "Lorem ipsum",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing.Facilis saepe id voluptas molestiae. Aperiam corrupti voluptas earum at molestiae!",
      cover: "./assests/images/personne.webp",
    },
    {
      id: 2,
      name: "LOREM IPSUM",
      post: "Lorem ipsum",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing.Facilis saepe id voluptas molestiae. Aperiam corrupti voluptas earum at molestiae!",
      cover: "./assests/images/personne.webp",
    },
    {
      id: 3,
      name: "LOREM IPSUM",
      post: "Lorem ipsum",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing.Facilis saepe id voluptas molestiae. Aperiam corrupti voluptas earum at molestiae!",
      cover: "./assests/images/personne.webp",
    },
  ]